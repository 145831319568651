
import Vue, { PropType } from "vue";

import { Member } from "@/interfaces";

export default Vue.extend({
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true,
    },
  },
});
