
import Vue, { PropType } from "vue";

import { Member } from "@/interfaces";

import MyOverview from "@/components/member/MyOverview.vue";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true
    }
  },
  computed: {
    hasEmail(): boolean {
      return !!this.member.user;
    }
  }
});
